export const REQUEST_MONSTER_LIST = 'REQUEST_MONSTER_LIST'
export const SET_MONSTER_LIST = 'SET_MONSTER_LIST'
export const SELECT_MONSTER = 'SELECT_MONSTER'
export const SET_MONSTER_VIEW_MODEL = 'SET_MONSTER_VIEW_MODEL'
export const SET_SELECTED_MONSTER_LOADING = 'SET_SELECTED_MONSTER_LOADING'
export const SET_MONSTER_ERROR = 'SET_MONSTER_ERROR'
export const SET_SELECTED_MONSTER_CATEGORY = 'SET_SELECTED_MONSTER_CATEGORY'
export const SELECT_MONSTER_CATEGORY = 'SELECT_MONSTER_CATEGORY'
export const SET_SELECTED_MONSTER = 'SET_SELECTED_MONSTER'
export const BUILD_VIEW_MODEL = 'BUILD_VIEW_MODEL'
